.footer {
    position: fixed;
    color: #000000;
    background: white;
    width: 100%;
    border-radius: 10px;
    z-index: 999;
    display: flex;
    justify-content: space-around;
    filter: drop-shadow(0px 2px 4px #0000005e);
    height: 5rem;
    align-items: center;
    bottom: 0px;
}


.footer-item{
    text-align: center;
    padding: 8px 10px 5px 10px !important;;
    background-color: white;
    color: #003282;
    border-radius: 8px;
}

.active{
    text-align: center;
    padding: 10px;
    background-color: #ffffff !important;
    color: #1167a3 !important;
    border-radius: 8px;
    font-weight: 600;
}

.header{
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 9999;
    display: flex;
    padding: 4.5%;
    height: 5rem;
    align-items: center;
    filter: drop-shadow(0px -1px 4px #0000005e);
    justify-content: space-between;
    background-color: #282c3f;
    border-radius: 0px 0px 20px 20px;

}


.order-view-head{
    margin: 20px;
}